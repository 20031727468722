import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedFloor: {},
    selectedTypology: {},
    amount:null,
    isLoading: true,
    selectedWing: {},
    userId:null,
    planId:null,
    isGifLoading:true
  },
  mutations: {
    setFloor(state, floor){
      state.selectedFloor = floor
    },
    setTypology(state, typology){
      state.selectedTypology = typology
    },
    setLoading(state, flag){
      state.isLoading = flag
    },
    setwings(state, wing){
      state.selectedWing = wing
    },
    setUserId(state,userId){
      state.userId = userId;
    },
    setAmount(state,amount){
      state.amount = amount;
    },
    setPlanId(state,planId){
      state.planId = planId
    },
    setIsGifLoading(state,isGifLoading){
      state.isGifLoading = isGifLoading;
    }
  },
  actions: {
    setFloor({commit}, floor){
      commit('setFloor', floor)
    },
    setTypology({commit}, typology){
      commit('setTypology', typology)
    },
    setLoading({commit}, flag){
      commit('setLoading', flag);
    },
    setwings({commit}, wing){
      commit('setwings', wing)
    },
    setUserId({commit},userId){
      commit('setUserId',userId);
    },
    setAmount({commit},amount){
      commit('setAmount',amount);
    },
    setPlanId({commit},planId){
      commit('setPlanId',planId);
    },
    setIsGifLoading({commit},isGifLoading){
      commit('setIsGifLoading',isGifLoading);
    }
  },
  getters: {
    selectedFloor(state){
      return state.selectedFloor;
    },
    selectedTypology(state){
      return state.selectedTypology;
    },
    isLoading(state){
      return state.isLoading
    },
    selectedWing(state){
      return state.selectedWing;
    },
    userId(state){
      return state.userId;
    },
    amount(state){
      return state.amount;
    },
    planId(state){
      return state.planId;
    },
    isGifLoading(state){
      return state.isGifLoading;
    }
  }
})