import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import VueSwal from 'vue-swal';
import underscore from 'vue-underscore';
import Datepicker from 'vuejs-datepicker';
import axios from 'axios'
import VueCarousel from 'vue-carousel';
import VueCookies from 'vue-cookies'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles/custom.scss';

let baseUrl="https://eoi-api.staging.property-pistol.in";
if(process.env.NODE_ENV === 'production'){
  baseUrl="https://eoi-api.staging.property-pistol.in";
}
axios.defaults.baseURL = baseUrl;
//axios interceptors
axios.interceptors.request.use(function(config) {
  store.dispatch('setLoading', true);
  config.headers['Content-Type'] = 'application/json'
  config.headers['Accept'] = 'application/json'
  return config;
}, function(err) {
  store.dispatch('setLoading', false);
  return Promise.reject(err);
});

axios.interceptors.response.use(function(response) {
  store.dispatch('setLoading', false);
  return response;
}, function(error){
  store.dispatch('setLoading', false);
  return Promise.reject(error);
});

Vue.component('date-picker', Datepicker)
Vue.use(VueSwal);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(underscore);
Vue.use(VueCarousel);
Vue.use(VueCookies,{expires:'7d',sameSite:'strict'})
Vue.prototype.$axios = axios;

Vue.config.productionTip = false
Vue.filter('asINR', function(value){
  if(!value) return '0'
  return value.toLocaleString('en-IN')
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
